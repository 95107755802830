interface Config {
	firebase: {
		app: {
			apiKey: string;
			appId: string;
			authDomain: string;
			databaseURL: string;
			measurementId: string;
			messagingSenderId: string;
			projectId: string;
			storageBucket: string;
		};
		firestore: {
			host: string;
			ssl: boolean;
		} | null;
	};
}

export const config: Config = {
	firebase: {
		app: {
			apiKey: 'AIzaSyCzXiyTj_R6Hx5VWLz28d3t1OIVK9XEnkc',
			appId: '1:574084151077:web:0a3efb0059fc35e25e8ccc',
			authDomain: 'marvil.cz',
			databaseURL: 'https://web-studio-marvil-default-rtdb.europe-west1.firebasedatabase.app',
			measurementId: 'G-EP778J8H2C',
			messagingSenderId: '574084151077',
			projectId: 'web-studio-marvil',
			storageBucket: 'web-studio-marvil.appspot.com',
		},
		firestore: null,
		// tslint:disable-next-line: comment-format
		// firestore: {
		//  	host: 'localhost:8081',
		// 	ssl: false,
		// },
	},
};
